import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Categories from "../data/categories";

const SubCategoryPage = () => {
  const params = useParams();
  console.log(params)
  const subCategoryData = Categories.categories.filter(
    (category) => category.id === params.id
  );
  console.log(params);

  return (
    <>
      <div>subcategory</div>
      <div className="flex flex-col mt-8 px-8 items-center">
        {subCategoryData[0].child_categories.map((item, index) => {
          return (
            <>
              <div className="bg-[#FAFAFA] border-[#ECF1F4] border-2 w-1/2 text-center mb-4 rounded-md py-2">
                {item.children && item.children.length ? (
                  <Link
                    to={`/select-job/${params.id}/sub-job/${item.id}`}
                    className="text-[#4A4F68]"
                  >
                    {item.name}
                  </Link>
                ) : (
                  <Link
                    to={`/create-job`}
                    state={{ job: item.name }}
                    className="text-[#4A4F68]"
                  >
                    {item.name}
                  </Link>
                )}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default SubCategoryPage;
