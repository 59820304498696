import { useEffect, useState } from "react"

const CarDetailsComponent = (props) => {

    const [carDetails, setDetails] = useState()

    console.log(props.reg)

    const [carReg, setCarReg] = useState()

    const getDetails = async () => {
        try {
            const response = await fetch('https://vast-gold-goldfish-yoke.cyclic.app/api/getDetails/' + props.reg[0])
            .then((res) => res.json())
            .then((data) => setDetails(data))
            return
        }
        catch (error) {
            console.log(error)
            return
        }
    }

    useEffect(() => {
        getDetails()
    },[])

    return (
        <>

            <div className="w-full px-4 lg:w-1/2">
                <form>
                    <fieldset className="border border-1 rounded-lg px-8 py-4">
                        <legend className="w-auto px-2">What is your car registration? *</legend>
                        <div className="">
                            <input value={props.reg[0]} onChange={(e) => setCarReg(e.target.value)} className="bg-[#FDD62E] placeholder-black text-xl font-bold rounded-md py-2 md:px-10  lg:px-10 text-center uppercase outline-none w-full lg:py-6 tracking-tighter" maxLength="7" placeholder="Enter your reg" type='text'></input>
                        </div>
                        <p className='text-[#4A4F68] font-bold mt-4 text-center'>{carDetails && `${carDetails.make} ${carDetails.model} ${carDetails.engine}cc `}</p>
                    </fieldset>
                </form>
            </div>
        </>
    )
}

export default CarDetailsComponent