const data ={
  "categories": [
    {
      "id": "1",
      "name": "Most Popular",
      "category_slug": "most-popular",
      "child_categories": [
        {
          "id": "1",
          "name": "MOT",
          "category_slug": "mot",
          "children": [
            {
              "image": require("../Assets/Icons/Wiper.png"),
              "name": "Servicing and MOT",
              "category_slug": "sevicing-and-mot"
            },
            {
              "image": require("../Assets/Icons/gearbox.png"),
              "name": "Clutch Repairs",
              "category_slug": "clutch-repairs"
            },
            {
              "image": require("../Assets/Icons/clutch.png"),
              "name": "Brake Repairs",
              "category_slug": "breake-repairs"
            },
            {
              "image": require("../Assets/Icons/tools.png"),
              "name": "Mobile Mechanics and Services",
              "category_slug": "mobile-mechanics-and-services"
            }
          ]
        }
      ]
    },
    {
      "id": "2",
      "name": "Other",
      "category_slug": "most-popular",
      "child_categories": [
        {
          "id": "1",
          "name": "MOT",
          "category_slug": "mot",
          "children": [
            {
              "image": require("../Assets/Icons/engine.png"),
              "name": "Engine & Cooling",
              "category_slug": "sevicing-and-mot"
            },
            {
              "image": require("../Assets/Icons/aircon.png"),
              "name": "Air-con, Heating & Cooling",
              "category_slug": "clutch-repairs"
            },
            {
              "image": require("../Assets/Icons/door.png"),
              "name": "Bodywork, Dents and Smart Repairs",
              "category_slug": "breake-repairs"
            },
            {
              "image": require("../Assets/Icons/breakdown.png"),
              "name": "Breakdown & Recovery",
              "category_slug": "mobile-mechanics-and-services"
            },
            {
              "image": require("../Assets/Icons/battery.png"),
              "name": "Electrical & Batteries",
              "category_slug": "mobile-mechanics-and-services"
            },
            {
              "image": require("../Assets/Icons/ev.png"),
              "name": "Hybrid and Electrical Vehicles",
              "category_slug": "mobile-mechanics-and-services"
            },
            {
              "image": require("../Assets/Icons/speaker.png"),
              "name": "Interior, Audio and Navigation",
              "category_slug": "mobile-mechanics-and-services"
            },
            {
              "image": require("../Assets/Icons/belt.png"),
              "name": "Safety Components",
              "category_slug": "mobile-mechanics-and-services"
            },
            {
              "image": require("../Assets/Icons/alarm.png"),
              "name": "Security, Locking and Keys",
              "category_slug": "mobile-mechanics-and-services"
            },
            {
              "image": require("../Assets/Icons/wheel.png"),
              "name": "Steering and Suspension",
              "category_slug": "mobile-mechanics-and-services"
            },
            {
              "image": require("../Assets/Icons/tyre.png"),
              "name": "Tyres, Wheels and Tracking",
              "category_slug": "mobile-mechanics-and-services"
            },
            {
              "image": require("../Assets/Icons/mirror.png"),
              "name": "Windows, Windscreens and Wing Mirrors",
              "category_slug": "mobile-mechanics-and-services"
            },
            {
              "image": require("../Assets/Icons/gearbox.png"),
              "name": "Gearbox Repairs",
              "category_slug": "mobile-mechanics-and-services"
            },
            {
              "image": require("../Assets/Icons/exhaust.png"),
              "name": "Exhaust Repairs",
              "category_slug": "mobile-mechanics-and-services"
            },
            {"image": require("../Assets/Icons/alloy.png"),
              "name": "Alloy Wheel Refurbishment",
              "category_slug": "mobile-mechanics-and-services"
            }
          ]
        }
      ]
    }
  ]
}


export default data