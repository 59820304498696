import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import NavigationBar from "./Components/navbar";
import CreateJob from "./Pages/createJob";
import LandingPage from "./Pages/homePage";
import CategoriesPage from "./Pages/categories_select";
import SubCategoryPage from "./Pages/SubCategoryPage";
import CustomerDetails from "./Pages/customerDetails";
import Footer from "./Components/footer";
import Tearms from "./Pages/terms";
import PrivacyPolicy from "./Pages/privacyPolicy";

function App() {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/create-job" element={<CreateJob />} />
        <Route path="/select-job" element={<CategoriesPage />}></Route>
        <Route path="/select-job/:id" element={<SubCategoryPage />}></Route>
        <Route path="/create-job/details" element={<CustomerDetails/>}></Route>
        <Route path="/terms-and-conditions" element={<Tearms/>}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}></Route>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
