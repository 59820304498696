import { Link } from "react-router-dom";
import Categories from "../data/categories";
import arrow from '../Assets/Icons/arrow.png'
import { useNavigate } from "react-router-dom";
import { setGlobalState } from "../global";
const CategoriesPage = () => {

  const navigate = useNavigate()

  const handleClick = (item) => {
    console.log(item)
    setGlobalState('customerJob' , item.name)
    setGlobalState('jobImage' , item.image)
    navigate('/create-job')
  }

  return (
    <>
      <div className="flex flex-col mt-8 px-8 items-center">
        {Categories.categories.map((item, index) => {
          return (
            <>
              <div className="sm:w-full lg:w-1/2">
                <div className="py-3">
                  {item.name}
                </div>
                <div className="">
                  {item.child_categories.children}
                  {item.child_categories.map((child) => {
                    return (
                      <>
                        {child.children.map((childCategory) => {
                          return (
                            <div onClick={() => handleClick(childCategory)} className="cursor-pointer bg-[#FAFAFA] border-[#ECF1F4] border-2 w-full text-center mb-4 rounded-md py-2">
                              <div className="ml-4 mr-2 flex flex-row items-center">
                                <img src={childCategory.image} width={55} height={55}></img>
                                <p className="ml-4 text-[#4A4F68] font-medium text-start">{childCategory.name}</p>

                              </div>
                            </div>
                          )
                        })}
                      </>
                    )
                  })}

                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export default CategoriesPage;
