import React from 'react'
import { BsNewspaper } from 'react-icons/bs'
import { ImCogs } from 'react-icons/im'
import { BsFillCreditCard2BackFill } from 'react-icons/bs'

const Howitworks = () => {
    return (
        <>
            <section className="mx-auto px-6 text-gray-800 md:max-w-screen-xl" id='how-it-works'>
                <div className="mx-auto mt-20 mb-16 block px-6 text-center">
                    <h2 className="text-4xl font-bold tracking-tight sm:text-5xl text-black">How it works.</h2>
                    <div className="mx-auto mt-6 mb-auto block w-full text-xl font-normal leading-9 text-gray-700 md:w-3/4 xl:w-3/4">
                        <p className="text-lg">At FindMyMechanic we aim to make the process as smooth as possible for our client.<br/> Simply enter your reg and fill out our form with your information and multiple mechanics will reach out to you with a quote. </p>
                    </div>
                </div>

                <div className="grid gap-10 pb-20 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div className="my-4 flex flex-col items-center">
                            <div className='flex bg-[#9672FF] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <BsNewspaper color='white' size={28} />
                            </div>
                            <h3 className="text-2xl font-bold md:text-xl">Fill out our form</h3>
                        </div>
                        <p className="text-gray-700 text-center">If you would like to get started with FindMyMechanic, Enter your registration above, select your problem and fill out your contact details.</p>
                    </div>
                    <div className="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div className="my-4 flex flex-col items-center">
                        <div className='flex bg-[#42ACF5] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <BsFillCreditCard2BackFill color='white' size={28} />
                            </div>
                            <h3 className="text-2xl font-bold md:text-xl">Get a quote</h3>
                        </div>
                        <p className="text-gray-700 text-center">Once the form has been submitted one of our specialists will reach out to you with our best possible price to get you back on the road.</p>
                    </div>
                    <div className="rounded-md border border-gray-200 bg-white p-8 shadow-sm">
                        <div className="my-4 flex flex-col items-center">
                        <div className='flex bg-[#F2B8EC] rounded-xl w-20 h-20 shadow-lg items-center justify-center mb-6'>
                                <ImCogs color='white' size={28} />
                            </div>
                            <h3 className="text-2xl font-bold md:text-xl">Sit back & relax</h3>
                        </div>
                        <p className="text-gray-700 text-center">Once you accept the quote with the mechanic just sit back and relax while our specilist mechanics get to work.</p>
                    </div>



                </div>
            </section>

        </>
    )
}

export default Howitworks