import { useState } from "react"
import { useGlobalState } from "../global"
import Swal from 'sweetalert2'

const CustomerDetails = () => {

    const [name, setName] = useState('')
    const [postCode, setPostCode] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [email, setEmail] = useState('')
    const [customMessage, setCustomMessage] = useState('')
    const [nearestCity, setnearestCity] = useState('')

    const [valid, setValid] = useState(true)

    const customerCarRegistration = useGlobalState('carRegistration')
    const customerJob = useGlobalState('customerJob')

    console.log('Customer Details: ' + customerCarRegistration[0])
    console.log('Customer Job: ' + customerJob[0])



    const saveJob = async (event) => {

        event.preventDefault()

        if (name && postCode && contactNumber && email && nearestCity !== ''){
        try {

            const response = await fetch('https://vast-gold-goldfish-yoke.cyclic.app/api/createJob', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: `{
                    "customerName" : "${name}",
                    "customerPhonenumber": "${contactNumber}",
                    "customerPostCode": "${postCode}",
                    "nearestCity": "${nearestCity}",
                    "customerCarRegistration": "${customerCarRegistration[0]}",
                    "customerProblem": "${customerJob[0]}",
                    "customMessage": "${!customMessage === '' ? customMessage: ' '}",
                    "customerEmail": "${email}"
                }`,
            }).then((res) => res.json()).then(() => Swal.fire({
                title: 'Success!',
                text: 'You job has been successfully posted, A mechanic will reach out to you shortly with quotes',
                icon: 'success',
                confirmButtonText: 'Ok'
              }))
              console.log(response)
        } catch (e) {
            console.log(e);
        }
    }else {
        setValid(false);
    }

    }


    return (
        <>
            <section className="bg-white">
                <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
                    <div className='text-center'>
                    <h2 className="mb-4 text-2xl font-bold text-[#126AF6]  dark:text-white">Get your quotes</h2>
                    <p className="text-md text-gray-400 dark:text-white mb-10">Mechanics as little as 1.1 miles from you are waiting to quote on your job.</p>
                    </div>
                    <form action="#">
                    <fieldset className="border border-1 rounded-lg px-8 py-2">
                    <legend className="w-auto px-2">Your Details *</legend>

                        <div className="mb-2">
                        <p className="mb-1">First Name</p>
                        <input value={name} onChange={(e) => setName(e.target.value)} type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Joe Boyle" required/>
                        </div>
                   
                   
                        <div className="mb-2">
                        <p className="mb-1">Post Code</p>
                        <input value={postCode} onChange={(e) => setPostCode(e.target.value)} type="text" name="brand" id="brand" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="BTXX XXX" required />
                        </div>

                        <div className="mb-2">
                        <p className="mb-1">Nearest City</p>
                        <input value={nearestCity} onChange={(e) => setnearestCity(e.target.value)} type="text" name="brand" id="brand" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="London" required />
                        </div>
             
                   
                        <div className="mb-2">
                        <p className="mb-1">Phone Number</p>
                        <input value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} type="number" name="price" id="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="+447927926604" required/>
                        </div>
                   
                    
                        <div className="mb-2">
                        <p className="mb-1">Email</p>
                        <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="example@mail.com" required />
                        </div>
             
                        <div className="mb-2">
                        <p className="mb-1">Additional Information</p>
                        <textarea value={customMessage} onChange={(e) => setCustomMessage(e.target.value)} id="description" rows="8" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Please enter any additional information the garage should know"></textarea>
                        </div>

                        <p className='text-red-400'>{valid ? '' : 'Please make sure all fields are filled'}</p>
                    </fieldset>
                    <div className="flex px-10 w-full justify-center">
                        <button onClick={(e) => saveJob(e)} className="bg-gray-700 w-full mt-6 py-3 md:w-full lg:w-1/2 text-white">Get Quotes</button>
                    </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default CustomerDetails