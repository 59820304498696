import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <footer aria-label="Site Footer" className="bg-gray-100">
                <div
                    className="relative mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 lg:pt-24"
                >
                    <div className="absolute top-4 right-4 sm:top-6 sm:right-6 lg:top-8 lg:right-8">
                        <a
                            className="inline-block rounded-full bg-[#42ACF5] p-2 text-white shadow transition hover:bg-[#3b9ddd] sm:p-3 lg:p-4 scroll-smooth"
                            href="#home"
                        >
                            <span className="sr-only">Back to top</span>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </a>
                    </div>

                    <div className="lg:flex lg:items-end lg:justify-between">
                        <div>
                            <div className="flex justify-center text-[#3b9ddd] lg:justify-start font-bold text-2xl">
                               FindMyMechanic
                            </div>

                            <p
                                className="mx-auto mt-6 max-w-md text-center leading-relaxed text-gray-500 lg:text-left"
                            >
                                FindMyMechanic is a company that specializes in automotive repairs.
                            </p>

                            <p
                                className="mx-auto mt-6 max-w-md text-center leading-relaxed text-gray-500 lg:text-left"
                            >
                                Appsnweb Limited <br/>71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ<br/>
                            </p>
                        </div>

                        <nav aria-label="Footer Nav" className="mt-12 lg:mt-0">
                            <ul
                                className="flex flex-wrap justify-center gap-6 md:gap-8 lg:justify-end lg:gap-12"
                            >
                                <li>
                                    <a className="text-gray-700 transition hover:text-gray-700/75" href="#home">
                                        Home
                                    </a>
                                </li>

                                <li>
                                    <a className="text-gray-700 transition hover:text-gray-700/75" href="#how-it-works">
                                        How it works
                                    </a>
                                </li>

                                <li>
                                    <a className="text-gray-700 transition hover:text-gray-700/75" href="#reviews">
                                        Reviews
                                    </a>
                                </li>
                                <li>
                                    <Link to='terms-and-conditions' className="text-gray-700 transition hover:text-gray-700/75" href="#reviews">
                                        T&C
                                    </Link>
                                </li>
                                <li>
                                    <Link to='privacy-policy' className="text-gray-700 transition hover:text-gray-700/75" href="#reviews">
                                        Privacy Policy
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <p className="mt-12 text-center text-sm text-gray-500 lg:text-right">
                        Copyright &copy; EngineSwap LLC 2022. All rights reserved.
                    </p>
                    
                </div>
            </footer>

        </>
    )
}

export default Footer