import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Howitworks from '../Components/how_it_works';
import Reviews from '../Components/testimonals';
import { setGlobalState } from '../global';



const LandingPage = () => {

    const [carReg, setCarReg] = useState('')
    const navigate = useNavigate();
    const [hasError, setHasError] = useState(false);


    const handleClick = () => {

        const checkReg = async () => {
            try {
                const response = await fetch('https://vast-gold-goldfish-yoke.cyclic.app/api/getDetails/' + carReg)
                    .then((res) => res.json())
                    .then((data) => {
                        if (data.model == '') {
                            setHasError(true);
                        } else {
                            setGlobalState('carRegistration', carReg);
                            navigate('/create-job')
                        }
                    })
            }
            catch (error) {
                console.log(error)
                return
            }
        }

        checkReg()
    };

    return (
        <>
            <div className="w-full px-6">
                <div className="mt-8 relative rounded-lg bg-indigo-500 container mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-48 xl:pb-48">
                    <img className="mr-2 lg:mr-12 mt-2 lg:mt-12 absolute right-0 top-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg2.svg" alt="bg" />
                    <img className="ml-2 lg:ml-12 mb-2 lg:mb-12 absolute bottom-0 left-0" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg3.svg" alt="bg" />
                    <div className="w-11/12 sm:w-2/3 mb-5 sm:mb-10">
                        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold leading-tight pb-8">Find local mechanics you can rely on</h1>
                        <h1 className="text-md sm:text-xl md:text-xl lg:text-2xl xl:text-2xl text-center text-white font-bold leading-tight">We'll help you save money on car repairs in just a few clicks.</h1>
                    </div>

                    {/* Centered div with yellow input box */}
                    <div className="flex justify-center items-center mb-5 w-3/4 md:w-1/2 lg:w-1/3">
                        <input value={carReg} onChange={(e) => setCarReg(e.target.value)} className="bg-[#FDD62E] placeholder-black text-xl font-bold rounded-md py-2 md:px-10  lg:px-10 text-center uppercase outline-none w-full lg:py-6" maxLength="7" placeholder="Enter your reg" type='text'></input>
                    </div>

                    <div className="flex justify-center items-center  mb-5 sm:mb-20 w-3/4 md:w-1/2 lg:w-1/3">
                        <button onClick={() => handleClick()} className="w-full hover:text-white hover:bg-transparent lg:text-xl  bg-[#007517] transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-[#007517] rounded text-white px-4 sm:px-8 py-2 sm:py-3 text-sm">Get A Quote</button>
                    </div>

                    {hasError ? <p className='text-white font-medium w-3/4'>Please enter a valid registration number.</p> : <></>}

                </div>
                <Howitworks id="how-it-work" />

                <div className='flex justify-center'>
                    <div className='rounded lg:w-3/4 md:w-3/4 h-auto flex bg-indigo-500 text-center justify-center'>
                        <div className='text-white my-4'>
                            <p className='text-xl md:text-3xl font-medium mb-2'>Looking to become a partner?</p>
                            <p className='text-sm md:text-md lg:text-md'>Join our mechanic partner programe and find jobs within your local area.</p>

                            <button onClick={event =>  window.location.href='https://dashboard.findmymechanic.co.uk/register' } className='text-[#1C3A54] bg-white px-4 py-1 md:px-6 lg:px-6 md:py-2 lg:py-2 rounded md:text-xl ld:text-xl font-medium mt-4'>Join Now</button>
                        </div>
                    </div>
                </div>

                <Reviews id='reviews' />
            </div>
        </>
    )
}

export default LandingPage